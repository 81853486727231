<template>
  <div>
    <div style="margin-bottom: 50px">
      <v-row style="margin: 0px 0px -25px">
        <v-col cols="4" align="center">
          <font size="1" color="#3c78d8"
            ><span class="text-sm-subtitle-1">連続正解</span></font
          >
        </v-col>
        <v-col cols="4" align="center">
          <font size="1" color="#f1c232"
            ><span class="text-sm-subtitle-1">1度正解</span></font
          >
        </v-col>
        <v-col cols="4" align="center" style="font-weight: 500">
          <font size="1"
            ><span class="text-sm-subtitle-1">不正解・未回答</span></font
          >
        </v-col>
      </v-row>
      <v-row>
        <v-progress-linear
          value="33"
          color="grey lighten-1"
          buffer-value="66"
          stream
          height="25"
        >
          <font size="1"
            ><span class="text-sm-subtitle-1"
              >セクション名（ 連続正解 / 1度正解 / 不正解・未回答 ）</span
            ></font
          >
        </v-progress-linear>
      </v-row>
    </div>
    <!-- 単元とセクション一覧 -->
    <div
      v-for="tangen in kamoku.sectionObj"
      :key="tangen.ind"
      style="margin-bottom: 15px"
    >
      <v-card color="ffffff" outlined width="100%" class="vCard">
        <v-card-title>
          <font size="3"
            ><span class="text-sm-h6">{{ tangen['name'] }}</span></font
          >
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="tangen[`checked`]"
            hide-details
            label="全選択"
            style="margin: 0px 0px 2px"
            @click="checkAllInTangen(tangen.sectionId)"
          ></v-checkbox>
        </v-card-title>
        <!-- セクション -->
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="4"
              v-for="section in tangen.sections"
              :key="section.ind"
            >
              <v-row style="margin: -20px -20px">
                <v-col cols="1">
                  <v-row justify="end" style="margin: 0px -25px">
                    <v-checkbox
                      v-model="section[`checked`]"
                      hide-details
                      style="margin: 0px 0px"
                      @click="sum_amountChecked()"
                    />
                  </v-row>
                </v-col>
                <v-col cols="11">
                  <v-progress-linear
                    :value="section[`progress`]"
                    color="grey lighten-1"
                    :buffer-value="section[`buffer`]"
                    stream
                    height="35"
                    width="100%"
                  >
                    <div style="width: 100%; line-height: 1">
                      {{ section[`name`] }}（{{ section[`text_amount`] }}）
                    </div>
                  </v-progress-linear>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div style="margin: 150px auto"></div>
    <!--フッター-->
    <v-footer app inset :color="footerColor">
      <v-row>
        <!-- モード commandの場合表示-->
        <v-col cols="6" xs="6" sm="3" v-if="kamoku_type == 'command'">
          <v-select
            :items="items.itemsMode"
            v-model="kamoku.option.selMode"
            item-text="text"
            label="モード"
            return-object
            hide-details
          ></v-select>
        </v-col>
        <!-- 出題ヒント regularの場合表示-->
        <v-col cols="6" xs="6" sm="3" v-if="kamoku_type == 'regular'">
          <v-select
            :items="items.itemsHint"
            v-model="kamoku.option.selHint"
            item-text="text"
            label="選択肢ヒント"
            return-object
            hide-details
          ></v-select>
        </v-col>
        <!-- 状態選択 未ログインなら非表示 集計関数のトリガーあり！-->
        <v-col cols="6" xs="6" sm="3" v-if="gf_isLogin()">
          <v-select
            multiple
            :items="items.itemsQuestionsState"
            v-model="kamoku.option.selQuestionState"
            item-text="text"
            label="出題対象"
            return-object
            hide-details
            @change="sum_amountChecked(kamokuName)"
            :disabled="disState"
          >
            <template v-slot:selection="{ index }">
              <div
                v-if="index == 0 && kamoku.option.selQuestionState.length == 4"
              >
                all
              </div>
              <div
                v-else-if="
                  index == 0 && kamoku.option.selQuestionState.length == 1
                "
              >
                {{ kamoku.option.selQuestionState[0]['text'] }}
              </div>
              <div
                v-else-if="
                  index == 0 && kamoku.option.selQuestionState.length >= 2
                "
              >
                ☑ {{ kamoku.option.selQuestionState.length }} selected
              </div>
            </template>
          </v-select>
        </v-col>
        <!-- 出題数 -->
        <v-col cols="6" xs="6" sm="3">
          <v-row>
            <v-col cols="8">
              <v-select
                :items="items.itemsAmountQ"
                v-model="kamoku.option.selAmountQ"
                label="出題数"
                return-object
                hide-details
                :disabled="disAmountQ"
              ></v-select>
            </v-col>
            <v-col cols="4" style="margin-top: 25px; margin-left: -10px">
              <span style="font-size: 20px">/{{ items.amountCheckedQ }}</span>
            </v-col>
          </v-row>
        </v-col>
        <!-- スタートボタン -->
        <v-col cols="6" xs="6" sm="3">
          <center>
            <v-btn
              rounded
              color="orange darken-1"
              dark
              max-width="200px"
              width="100%"
              style="margin-top: 10px"
              @click="kamokuStart()"
            >
              Start<v-icon right>mdi-hand-pointing-right</v-icon>
            </v-btn>
          </center>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<style scoped>
.selBox {
  margin: -0px 0px;
}
</style>
<script>
import { mapState } from 'vuex';
export default {
  name: 'Questions',
  data() {
    return {
      isPageComplete: false,
      kamokuName: 'kamokuDefault', //いったんデフォルトの科目オブジェクトを読み込んでレンダリングエラーを回避しています
      kamoku_type: 'regular', //いったん初期値を読み込ませてレンダリングエラーを回避している
      disState: false, //コマンド問題用、テストが選択されると、状態選択を選択不可にする
      disAmountQ: false, //コマンド問題用、テストが選択されると、問題数を選択不可にする
    };
  },
  computed: {
    ...mapState(['conf', 'items']),
    kamoku() {
      const obj = this.$store.state[this.kamokuName];
      return obj;
    },
    //watchのkeyには変数を指定できないので、ここで値を定義する。
    selMode() {
      return this.$store.state[this.kamokuName].option.selMode;
    },
    //問題が選択されたら色を変えて初見ユーザーに教える
    footerColor() {
      const amount = this.$store.state.items.amountCheckedQ;
      if (0 < amount) {
        return 'orange lighten-3';
      } else {
        return 'grey lighten-3';
      }
    },
  },
  watch: {
    //computedで定義した値（state）に変更があった場合の処理
    selMode(val) {
      this.$store.commit('update_store', {
        path: this.kamokuName + '.option',
        key: 'selAmountQ',
        data: 'all',
      });

      const itemsQuestionsState = this.items.itemsQuestionsState;
      const path = this.kamokuName + '.option';

      if (val.value == 'test') {
        //state選択オブジェクトを全て取得→selQuestionsStateに代入すれば、全選択になる。
        this.$store.commit('update_store', {
          path: path,
          key: 'selQuestionState',
          data: itemsQuestionsState,
        });
        this.disState = true;
        this.disAmountQ = true;
      } else {
        //最初の２つが未回答と不正解
        const selQuestionState = [];
        selQuestionState.push(itemsQuestionsState[0]);
        selQuestionState.push(itemsQuestionsState[1]);
        this.$store.commit('update_store', {
          path: path,
          key: 'selQuestionState',
          data: selQuestionState,
        });
        this.disState = false;
        this.disAmountQ = false;
      }
    },
  },
  methods: {
    //-------------------------------------
    init(kamokuName) {
      this.kamokuName = kamokuName;
      this.kamoku_type = this.conf.itemsKamoku[this.kamokuName].kamoku_type;
      this.$store.state.items.amountCheckedQ = 0; //本当はitemごと初期化したい
    },
    //選択された問題数を集計する
    sum_amountChecked() {
      const obj_sectionObj = this.$store.state[this.kamokuName].sectionObj;
      const arrayObj_sectionObj =
        this.utils_create_arrayObjFromObj(obj_sectionObj);

      let amountCheckedQ = 0;
      arrayObj_sectionObj.forEach((ele) => {
        const arrayObj_sections = this.utils_create_arrayObjFromObj(
          ele.sections,
        );

        let amount = arrayObj_sections.reduce((acc, cur) => {
          if (cur.checked == true) {
            return acc + cur.unanswered; //未ログイン時は未回答が問題数
          } else {
            return acc;
          }
        }, 0);

        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        if (this.gf_isLogin()) {
          amount = this.get_checkedAmountByState(
            arrayObj_sections,
            this.kamokuName,
          );
        }

        amountCheckedQ += amount;
      });

      this.$store.commit('update_store', {
        path: 'items',
        key: 'amountCheckedQ',
        data: amountCheckedQ,
      });
    },
    checkAllInTangen(tangenId) {
      this.$store.commit('checkAll', {
        path: this.kamokuName + '.sectionObj',
        tangenId: tangenId,
      });
      this.sum_amountChecked();
    },
    //ユーザーが選択した問題状態について、その状態にある問題数を集計する
    get_checkedAmountByState(arrayObj_sections, kamokuName) {
      const arrayObj_selQState =
        this.$store.state[kamokuName].option.selQuestionState;
      let sum = 0;
      arrayObj_sections.forEach((obj_sections) => {
        if (obj_sections.checked == false) {
          return;
        }
        arrayObj_selQState.forEach((obj) => {
          const state = obj.value;
          sum = sum + Number(obj_sections[state]);
        });
      });
      return sum;
    },
    //-------------------------------------
    //問題演習を始める
    kamokuStart() {
      const obj_selSectionId = this.get_selSectionId();

      let res = this.validate_freeUser(obj_selSectionId);
      if (!res.flag) {
        alert(res.msg);
        return;
      }

      if (this.gf_isLogin()) {
        res = this.validate_loginUser();
        if (!res.flag) {
          alert(res.msg);
          return;
        }
      }

      this.contQ_init({
        selSectionId: obj_selSectionId,
        kamokuName: this.kamokuName,
      });

      const kamoku_type = this.gf_get_kamokuType(this.kamokuName);
      if (kamoku_type == 'regular') {
        this.$router.push('/quiz');
        return;
      }
      if (kamoku_type == 'command') {
        this.$router.push('/quizCommand');
        return;
      }
    },
    //出題対象を割り出すためにcheckedなsectionIdを取得して、stateに保管する
    get_selSectionId() {
      var selSectionId = {};
      const sectionObj = this.$store.state[this.kamokuName].sectionObj;
      Object.keys(sectionObj).forEach((key) => {
        const sections = sectionObj[key]['sections'];
        Object.keys(sections).forEach((key2) => {
          const isChecked = sections[key2]['checked'];
          if (isChecked) {
            const sectionId = sections[key2]['sectionId'];
            const obj = {
              tangenId: sections[key2]['tangenId'],
              sectionId: sectionId,
            };
            selSectionId[sectionId] = obj;
          }
        });
      });
      return selSectionId;
    },
    validate_freeUser(obj_selSectionId) {
      let msg = '⚠ invalid';
      let flag = true;
      if (Object.keys(obj_selSectionId).length == 0) {
        msg += '\n・問題が選択されていません';
        flag = false;
      }
      if (this.items.amountCheckedQ == 0) {
        msg += '\n・出題できる問題がありません';
        flag = false;
      }
      return { flag: flag, msg: msg };
    },
    validate_loginUser() {
      let msg = '⚠ invalid';
      let flag = true;
      const selQuestionState =
        this.$store.state[this.kamokuName].option.selQuestionState;
      if (selQuestionState.length == 0) {
        msg += '\n・出題対象（問題の状態）が選択されていません';
        flag = false;
      }
      return { flag: flag, msg: msg };
    },
  },
};
</script>
